import React from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../components/structure';
import { Content, Hero } from "../../components/blocks"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Hero title={'Privacy Policy'} />
    <Content>
      <div  style={{width: '90%', maxWidth: '1080px', margin: 'auto'}}>
      <p style={{textAlign: 'left'}}>
      Points International Ltd., Points.com Inc., Points International (UK) Limited, Points International (U.S.) Ltd., Points Development US Ltd. and Points Travel Inc. (each a "Points Affiliate", and collectively, "Points", "we", "us" or "our") take your privacy seriously. 
      </p>
      <p style={{textAlign: 'left'}}>
      This Privacy Policy (“Privacy Policy”) sets out our practices with respect to the collection, use, disclosure of the personal information of individuals ("you", “your”) who interact with us, including when you visit or view one of our websites at www.points.com ("Points.com"), pointshound.com ("PointsHound.com"), company.points.com (together with Points.com and PointsHound.com and our mobile applications, our "Sites"), establish a relationship with us, sign up to receive communications or sign up for or use any Points services, subject to certain exclusions as more fully explained in the “Scope of this Privacy Policy” section below. This Privacy Policy also sets out the rights you may have with respect to your personal information. 
      </p>
      <p style={{textAlign: 'left'}}>
      At the end of the Privacy Policy, we provide you with information on how to contact our Data Privacy Officer and, for those individuals residing in the European Economic Area, our EU Representative.
      </p>
      <p style={{textAlign: 'left'}}>Please read this Privacy Policy carefully to understand our policies and practices for collecting, processing, and storing your personal information. By accessing or using our Sites or our services, or otherwise interacting with us, you consent to our collection, use and disclosure of your personal information as outlined in this Privacy Policy. Your consent is the legal basis for our use of your personal information. You can withdraw your consent at any time. However, if you withdraw your consent to certain uses, we may no longer be able to provide our services to you.</p>

      <h3>What is “Personal Information”?</h3>
      <p style={{textAlign: 'left'}}>In this Privacy Policy, the term “personal information” means any information that relates to you as an identified or identifiable individual (i.e., a natural person). It includes “personal information” “personally identifiable information” and “personal data” as each of those terms are defined in applicable privacy and data protection laws. It may also include other types of more technical information, but only when this information can be used to identify an individual. Information that is aggregated and/or anonymized, or otherwise cannot be associated with an identifiable individual is not considered to be personal information.  </p>
      
      <h3>Scope of this Privacy Policy</h3>
      <p style={{textAlign: 'left'}}>We collect personal information from:</p>
      <ul>
        <li>visitors to our Sites;</li>
        <li>
        individuals who establish a relationship with us by telephone, sign up to receive communications or who sign up for or use any Points services; and
        </li>
        <li>
        customers of those of our third-party our loyalty program and redemption partners who have engaged us to deliver services on their behalf (collectively, our "Partners"), to the extent that you have given consent to the Partner to provide us with such personal Information.
        </li>
        <p style={{textAlign: 'left'}}>This Privacy Policy sets out our practices with respect to the collection, use, disclosure of the personal information of individuals who fall into the first two categories. <strong>This Privacy Policy does not address our processing of personal information of customers of our Partners.</strong></p>
        <p style={{textAlign: 'left'}}>Some laws distinguish between a “controller” on the one hand, and a “processor” or “service provider” on the other (or similar terms). With respect to personal information that we collect, use, disclose or otherwise process on behalf of our Partners, we are service providers or processors for the purposes of such laws. <strong>When Points processes personal information in this way, we are acting at the direction of the Partner, and the Partner's terms of service and privacy policy apply—not ours. </strong></p>
        <p style={{textAlign: 'left'}}>For more information about how a Partner collects, uses and discloses your personal information as a customer of that Partner, please review that Partner's terms of service and privacy policy.</p>
      </ul>
      <p style={{textAlign: 'left'}}>
        <i>Children</i>
      </p>
      <p style={{textAlign: 'left'}}>
      Our Sites are not intended for children.  If we become aware that we have inadvertently received or collected personal information pertaining to a child under the age of consent in the country where the child is located without valid consent, we will delete such information from our records.
      </p>
      
      
      <h3>What Personal Information We Collect</h3>
      <p style={{textAlign: 'left'}}>We may collect personal information which includes:</p>
      <ul>
        <li>
        user account information that you upload on our Sites, such as your picture, name, home address, email address and phone number;
        </li>
        <li>
        loyalty program account information for the third party loyalty programs you register with our Sites, including your program account numbers, passwords and balances, and details of buy, sell, trade and redemption transactions involving those loyalty programs;
        </li>
        <li>
        information for travel bookings through our Sites, including personal information of people you make reservations or bookings for using our Sites. It is your responsibility to obtain the consent of other individuals prior to providing us with their personal information;
        </li>
        <li>
          information about any other services booked or purchases made through
          our Sites;
        </li>
        <li>
          purchase and sale transaction information such as your credit card
          information and the details of your transaction;
        </li>
        <li>
          information contained in any email messages, questions, comments,
          complaints or requests you send us;
        </li>
        <li>your current geographic location;</li>
        <li>your IP address;</li>
        <li>
        information related to your use of our Sites or services, including the hardware, software or internet browser you used, and information about your computer’s operating system, such as application versions and your language settings, as well as device identifiers and error reporting information from devices on which you use our apps;
        </li>
        <li>
          information about which pages have been shown to you, site navigation
          details and links on our Sites that you follow;
        </li>
        <li>
          information you submit to our Sites in connection with sweepstakes,
          contests, surveys and other promotions offered by us; and
        </li>
        <li>
          information, including images, contained in posts you make about
          Points and its services on publicly accessible websites, blogs, mobile
          applications and/or community forums.
        </li>
        <i>Special Categories of Personal Information</i>
        <p style={{textAlign: 'left'}}>Certain jurisdictions have laws that define special categories of personal information. For the purposes of those laws, we do not process special categories of personal information, namely, personal information that reveals racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person’s sex life or sexual orientation. </p>

      </ul>
      <h3>How We Collect Personal Information </h3>
      <p style={{textAlign: 'left'}}>We collect personal information in a variety of ways, including:</p>

      <ul>
        <li>Directly from you, such as when you visit our Sites or interact with us by mail, email, over the telephone, or in any other direct manner.</li>
        <li>Through technologies we use online, such as when you interact with our websites, marketing emails, social media accounts, online advertising, or through the use of our or a third party’s technologies, which include cookies, web beacons or single pixel gifs or analytics engines. The technologies we use include
          <ul style={{listStyleType:'circle'}}>
            <li>Cookies and JSON Web Tokens (please see the section entitled “Cookies and JSON Web Tokens” below).  </li>
            <li>Clear GIFs (please see the section entitled “Clear GIFs” below).</li>
            <li>Web analytics tools such as Google Analytics, which uses cookies to analyze your use of our website, to create reports about visitor and user activities for us and to provide further services associated with the use of our website.</li>
          </ul>
        </li>
      
      </ul>
      <p style={{textAlign: 'left'}}>Please note that third parties involved in providing these technologies may use tracking methods such as but not limited to cookies to achieve their own business goals and purposes by relating and combining information about your usage of our website to any other personal data they may have collected on you. We encourage you to review the privacy policies of third parties as well. </p>
      
      
      <h3>How We Use Personal Information</h3>
      <p style={{textAlign: 'left'}}>
      We may use your personal information for the following purposes, except as otherwise permitted or required by applicable law:
      </p>
      <strong>providing our services,</strong> including:
      <ul>
        <li>
          allowing you to track, manage and access your loyalty points and miles
          on our Sites;
        </li>
        <li>
          allowing you to gather and view your travel information on our Sites;
        </li>
        <li>
          processing transactions that you have submitted to us and displaying
          your account history to you;
        </li>
        <li>providing customer service;</li>
        <li>for improved service, when you make calls to our Customer Service Team, relating your telephone number to your existing reservations using an automated telephone number detection system;</li>
        <li>
        communicating with you by email, mail and phone to handle any requests you or your booked accommodations have made; and
        </li>
        <li>acknowledging your comments and/or replying to your questions, complaints and requests
        </li>

      
        <strong>managing our operations,</strong> including:
        <li>
        deploying and managing our information technology applications and systems, including managing our Sites;
        </li>
        <li>
        operating and maintaining, and improving the performance and utility of, our services;
        </li>
        <li>
        generally managing and administering our business;
        </li>
        <li>notifying you of changes to our services, terms, conditions or policies and to provide you administrative messages, updates, legal notices, technical notices or security alerts;</li>
        <li>
        meeting our contractual and legal obligations (including accounting, taxes and other legal or regulatory compliance obligations);
        </li>
        <li>
        maintaining the security of the public, our users and our employees; and
        </li>
        <li>preventing and protecting ourselves and third parties from errors, fraud and credit risk; 

</li>
<strong>marketing,</strong> including
        <li>providing you with information on and direct marketing of special offers or promotions (including sweepstakes and contests) that we believe may be of interest to you;</li>
        <li>serving advertisements to you in various marketing channels, including but not limited to social media, search engines, mobile apps, and various websites including our own Sites; and</li>
        <li>notifying you of offers of Points products and services that may be of interest to you;</li>
        <strong>research and analytics,</strong> including:
        <li>market research: we sometimes ask our customers to take part in market research. Any additional personal details that you give us as part of the market research will be used only with your consent;</li>
        <li>profiling and analytical purposes, including marketing and website analytics, in order to optimize and customize our online platform to your needs, as well as the products and services marketed to you, and to make the Sites easier to use; and</li>
        <p style={{textAlign: 'left'}}><strong>any other purpose to which you may consent in the future.</strong></p>
      </ul>
     
     
      <p style={{textAlign: 'left'}}>
        <i>Limited to Disclosed Purposes</i>
      </p>
      <p style={{textAlign: 'left'}}>
      We limit the collection, use, and disclosure of personal information to that which is needed to achieve the above noted purposes, except as otherwise permitted or required by applicable law.
      </p>
      <p style={{textAlign: 'left'}}>
        <i>Special Note on Profiling and Automated Processing</i>
      </p>
      <p style={{textAlign: 'left'}}>
      We may analyse your personal information (including through the use of automated processes) in order to create a profile of your interests and preferences so that we can contact you with information or to offer you services that are relevant to you. We may make use of additional information about you when it is available from our Partners and other external sources to help us do this effectively. We may also use and analyse your personal information to detect and reduce fraud and credit risk. For more information, please contact us using the coordinates provided in the section entitled “Contact Us”, below.
      </p>
      
      
      <h3>How We Share Your Personal Information</h3>
      <p style={{textAlign: 'left'}}>
      We will only share your personal information in the following circumstances, except as otherwise permitted or required by applicable law:
      </p>
      <ul>
        <li>
        <strong>with affiliates:</strong> We may share your personal information between Points affiliates, to allow Points affiliates to offer their products and services to you;
        </li>
        <li>
        <strong>with Partners and other third party service fulfilment partners:</strong> We may share personal information with third parties as necessary to provide you with services that you have requested or to fulfill the transactions you have submitted, such as hotels and airlines who provide services accessed through our Sites. Please also see the section “Third Parties” below;
        </li>
        <li>
        <strong>with our service providers:</strong> to third party companies and contractors that provide us with services in the conduct of our business ("service providers"), such as payment processing services, information technology services for our software applications, advertising, marketing and survey services and other essential similar services, but only as necessary for those service providers to provide services to us. All Points service providers are contractually bound to keep your information secure. Our service providers can use, store and disclose your personal information solely for purposes for which it is disclosed to them (to provide us with services);
        </li>
        <li>
        <strong>for business transactions:</strong> We may use and disclose your personal information in connection with the proposed or actual financing, sale or other business transaction involving part or all of our business or assets. Such use and disclosure would be for the purpose of allowing third parties to determine whether to proceed with the proposed transaction, and if the transaction proceeds, for the purpose of completing the transaction. Assignees or successors to our business or assets may use and disclose your personal information for the purposes described in this policy. You will be notified via email and/or a prominent notice on our Sites of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information; 
        </li>
        
        
        <li><strong>as permitted or required by law:</strong> applicable laws may permit or require the use, sharing, or disclosure of personal information without consent in specific circumstances. These circumstances include situations where it is necessary to disclose information in response to subpoenas, warrants, or court orders, or in connection with any legal process, or to comply with relevant laws. We may also share your information in order to establish or exercise our rights, to defend against a legal claim, to investigate, prevent, or take action regarding possible illegal activities, suspected fraud, safety of person or property, or a violation of our policies. If this happens, we will not share more personal information than is reasonably required to fulfill that particular purpose; and</li>
        <li>
          <strong>any other circumstance in which you provide your consent to disclose, or a consent exception applies, in accordance with applicable laws</strong>
        </li>
      </ul>

      <h3>Cross-Border Transfers</h3>
      <p style={{textAlign: 'left'}}>
      Points, our Partners and our service providers are located in various jurisdictions including, among others, Canada, the United States and the European Union and, as a result, your personal information may be stored, processed or transferred into or out of the jurisdiction in which you are located or where you reside (e.g., your country, state or province). The privacy and data protection laws in these jurisdictions may be different to those in your jurisdiction and your information may be subject to the laws of, and to access by the regulatory, governmental or law enforcement authorities in, those other jurisdictions.
      </p>

      <h3>Third Parties </h3>
      <p style={{textAlign: 'left'}}>
        <i>Third Party Services Accessed Through Our Sites</i>
      </p>
      <p style={{textAlign: 'left'}}>
      When you provide personal information to us, we may be required, in order to provide you with services, to provide that personal information to our Partners and other third parties, such as hotels and airlines who provide services accessed through our Sites. Those companies are not contractually bound to comply with this Privacy Policy. Our Partners and other third parties use and disclose your personal information according to their own policies and for their own purposes. We encourage you to read our Partners' and other third parties’ privacy policies before obtaining their products or services through our Sites.
      </p>

      <i>Links to Third Party Sites</i>
      <p style={{textAlign: 'left'}}>We may provide links or references to third party sites whose privacy practices may differ from those of Points. You may navigate to a Points site through a link from a Partner or other third party site.  Points assumes no responsibility for the content or the privacy policies of those Partner or third party websites. Please be aware that when you follow any links to another site not owned or operated by Points and submit personal information to any of those sites, you are subject to their privacy policies. We encourage you to carefully read the privacy policy of any website you visit. </p>

    <i>Social Media Features</i>
    <p style={{textAlign: 'left'}}>Our Sites include social media features, such as the Facebook button and widgets, among other things. These features may collect your IP address, track which page you visit on our Sites, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our Sites. Your interactions with these features are governed by the privacy policy of the company providing it. We encourage you to carefully read the privacy policy of any company that provides the features you choose to interact with.</p>
      <h3>
      Data Retention
      </h3>
      <p style={{textAlign: 'left'}}>
      We will retain your personal information for as long as
      </p>

      <ul>
        <li>your account is active;</li>
        <li>your personal information is needed to provide you services; or</li>
        <li>it is necessary to meet legal, regulatory, insurance and audit requirements.</li>
      </ul>
      <p style={{textAlign: 'left'}}>
      If you wish to cancel your account or request that we no longer use your information to provide you services, contact us at <a href="mailto:dpo@points.com?subject=Request from Points.com -.">
          dpo@points.com
        </a>.
      </p>
      
      
      
      
     
      <h3>Accuracy of Your Personal Information </h3>
      <p style={{textAlign: 'left'}}>We will use commercially reasonable efforts to ensure that your personal information is correct, complete and up-to-date to the extent we are notified of any changes by you. You can review and update your personal information on our Sites. You can assist by keeping us informed of any changes, or informing us if you find any errors in our information about you.  If we have disclosed inaccurate information about you to a third party that you are aware of, we would be pleased to contact the third party in order to correct the information upon your request.</p>
      <h3>Your Rights with Respect to Your Personal Information</h3>
      <i>Access</i>
      
      <p style={{textAlign: 'left'}}>
      You are entitled to be informed or and have access to any of your personal information in our custody or control.  Upon written request to our Privacy Officer as noted at the bottom of this page, we will provide you with your personal information under our custody or control, as well as information regarding how your personal information is being used and the identity of any third party(s) to whom that information has been disclosed.  Please note that we may not be able to provide access to personal information in certain circumstances, such as if the personal information was collected for the purposes of an investigation, if disclosure would reveal the personal information of a third party, or if prohibited by law.  To protect your privacy and security, we may take reasonable steps to verify your identity prior to providing such access.  
      </p>
      <i>Correction</i>
      <p style={{textAlign: 'left'}}>
      If you wish to correct any of your personal information we have on file in our records, other than that available to you and capable of being modified through the Services, you may contact our Privacy Officer as noted at the bottom of this page.  To protect your privacy and security, we may take reasonable steps to verify your identity prior to making corrections.
      </p>
      <i>Rights of European Data Subjects</i>
      <p style={{textAlign: 'left'}}>
      In addition to rights with respect to your personal data already noted elsewhere in this policy, for those individuals residing in the European Economic Area, you have certain additional rights under the General Data Protection Regulation (GDPR):
      </p>
      
        <ul><li>Restrictions on Further Processing</li>
        <p style={{textAlign: 'left'}}>
        You have the right, where there is a dispute in relation to the accuracy
        or basis of processing of your personal data, to request a restriction
        on further processing by us.</p>
      
        <li>Erasure</li>
        <p style={{textAlign: 'left'}}>You have the right to erasure of your personal data (“right to be forgotten”) in certain circumstances. </p>
        
        <li>Data Portability</li>
      
      <p style={{textAlign: 'left'}}>
      You have the right to request that personal data that you have provided to us be returned to you, or be provided to another third party of your choice, in a structured, commonly used and machine-readable format.
      </p>
        
      
        <li>Automated Decision-Making</li>
     
      <p style={{textAlign: 'left'}}>
      You have the right not to be subject to a decision based solely on automated decision-making.  In connection with such right, you may have the right to request human intervention with respect to such automated decision-making, as well as express your point of view or contest any such automated decision-making.
      </p>
      <li>Opt-out of Direct Marketing / Profiling</li>
      <p style={{textAlign: 'left'}}>You may elect to “opt out” of direct marketing activities, including any profiling we may conduct for direct marketing purposes.  Accordingly, we may rely on your consent permitting us to process your information in accordance with this policy for direct marketing, including profiling for direct marketing, unless you take affirmative action to opt out of such processing. You may opt out at any time by contacting our Data Privacy Officer or EU Representative, as provided further below.</p>
      <li>Lodge Complaints with Supervisory Authorities</li>  
      <p style={{textAlign: 'left'}}>You have the right to lodge a complaint with a data protection supervisory authority in your country.</p>
        </ul>
      
      
      
      
      
      
  
      <h3>Cookies and JSON Web Tokens</h3>
      <p style={{textAlign: 'left'}}>
      A cookie is a small text file that is stored on a user's computer for record-keeping purposes. JSON web tokens, or JWT tokens, are authentication confirmations that are used to authenticate a user. We use both session ID cookies and persistent cookies, as well as JWT tokens on our Sites. Session ID cookies expire when you close your browser. Persistent cookies remain on your hard drive for an extended period of time. You can remove persistent cookies by following directions provided in your Internet browser's "help" file. Cookies provide us with information on your use of our Sites, including the time and length of your visit, the pages you look at on our Sites, the website you visited just before coming to ours, and the name of your Internet service provider.
      </p>
      <p style={{textAlign: 'left'}}>
      We use session cookies and tokens to make it easier for you to navigate our Sites. We use persistent cookies and tokens to store your username, so you don't have to enter it more than once, and to track and target your interests, to evaluate the performance of our Sites, and to tailor promotions and other marketing messages to you and enhance your experience on our Sites.
      </p>
      <p style={{textAlign: 'left'}}>
        You can reject cookies by adjusting the preference settings in your
        browser. If you reject our cookies, you may not be able to take full
        advantage of certain features available on our Sites, such as contests
        and surveys. You can learn more about interest-based advertising by
        visiting{" "}
        <a
          
          rel="noopener noreferrer"
          href="http://www.networkadvertising.org/managing/opt_out.asp"
        >
          http://www.networkadvertising.org/managing/opt_out.asp
        </a>{" "}
        and{" "}
        <a
          rel="noopener noreferrer"
          
          href="http://www.aboutads.info/choices"
        >
          http://www.aboutads.info/choices
        </a>
        .
      </p>
      <p style={{textAlign: 'left'}}>You can also selectively enable or disable certain cookies we use. For more information on the cookies we utilize, and how to enable or disable them, please see our Cookie Settings.</p>
      <h3>Clear Gifs</h3>
      <p style={{textAlign: 'left'}}>
      Clear GIFs (also known as tracking pixels or web beacons) are tiny graphics with a unique identifier, similar in function to cookies. They are used to track the online movements of users. In contrast to cookies, which are stored on a user's computer hard drive, clear gifs are embedded invisibly on web pages and are about the size of the period at the end of this sentence.
      </p>
      <p style={{textAlign: 'left'}}>
      We and our service providers use clear gifs to help us better manage content on our Sites, by informing us what content is effective, and to gauge the effectiveness of certain communications and marketing campaigns. For instance, we use clear gifs in our HTML-based emails to let us know which emails have been opened by recipients and to allow us to serve you related advertising on the Internet. 
      </p>
      
      <h3>Security Measures</h3>
      <p style={{textAlign: 'left'}}>
      We take reasonable measures, including administrative, physical and technical safeguards, to protect your information from loss, theft, misuse, unauthorized access, disclosure, alteration and destruction, in light of, among other things, the sensitivity of the information and the purposes for which it is to be used. Personal information uploaded to our Sites is transmitted to us using industry standard encryption, which creates a private conversation between your computer and our Sites. We authenticate our Sites and enable TLS/SSL encryption to protect your sensitive data and transactions.
      </p>
      <p style={{textAlign: 'left'}}>
      We maintain policies and practices to protect your personal information, including our Information Security Policy, Backup Procedures, Encryption Policy, Data and Information Classification Policy, Record Retention Policy, Disposal Policy, Risk Management Policy, DPIA Process Template, Information Security Incident Management Policy and Procedure, Vendor Management Policy, and Audit Policy. These policies and practices (i) provide a framework for our collection, use and disclosure of personal information throughout its lifecycle, including the security and proper functioning of our information systems, data processing practices, and backup systems, our record retention and disposal policies, our procedures for assessing the privacy impact of our business processes and vendors we engage to process personal information, and our practices in relation to assessing risks, conducting security audits, and responding to actual or suspected security incidents; and (ii) define internal roles and responsibilities in connection with the foregoing.
      </p>
      <p style={{textAlign: 'left'}}>
      We also maintain internal processes for dealing with complaints regarding the protection of personal information, as referred to in the section entitled “Disputes and Complaints” below, and for responding to requests to exercise individual rights. For more information, please contact us using the coordinates provided in the section entitled “Contact Us”, below.
      </p>
      <p style={{textAlign: 'left'}}>Please note that, notwithstanding the efforts we make to protect your personal information, Points cannot absolutely guarantee that unauthorized third parties will never be able to defeat our security measures or use your personal information for improper purposes. In the event that your personal information in our possession or under our control is compromised as a result of a security breach, we will take reasonable steps to investigate the situation and, where appropriate, notify you and take other steps in accordance with applicable laws or regulations.</p>
      <p style={{textAlign: 'left'}}>Remember that email sent over the Internet is generally unencrypted and transmitted in clear text. We recommend that you use caution when forwarding free-format email messages to us and that you do not include confidential information (such as unique user IDs, passwords or personally identifiable information) in those messages. Confidential information should be transmitted to us through other secure methods such as by telephone. </p>
      
      <h3>Revision of Policy</h3>
      <p style={{textAlign: 'left'}}>
      This policy may be revised from time to time. We will notify you of material changes prior to such material change being effective, either by e-mail at the address you have provided to us in connection with your registration on our Sites or by a prominent notice on our Sites. Your continued use of our Sites or services, failure to cancel your account on our Sites, or  interaction with us following such notification shall constitute your acceptance of the revised policy. We encourage you to periodically review this page for the latest information on our privacy practices. The date provided at the end of the Privacy Policy indicates when it was last updated.
      </p>
      <h3>Disputes and Complaints</h3>
      <p style={{textAlign: 'left'}}>
        This policy is subject to our{" "}
        <Link to="/legal/terms/">Terms of Use</Link>, including terms
        regarding the governing law and the resolution of any disputes between
        you and Points.
      </p>
      <p style={{textAlign: 'left'}}>
        If you have a complaint with respect to our privacy policy please
        contact us as provided below. For those individuals residing in the
        European Economic Area, you also have the right to lodge a complaint
        with a data protection supervisory authority in your country.
      </p>
      <h3>Contact Us</h3>
      <p style={{textAlign: 'left'}}>
        The Data Privacy Officer is accountable for our compliance with this
        privacy policy and applicable privacy legislation.
      </p>
      <p style={{textAlign: 'left'}}>
      If you have questions, comments or complaints with respect to our Privacy Policy or if you wish to request access to, or correction or deletion of your personal information under Points' care and control, please contact the Data Privacy Officer at:
      </p>
      <div>Data Privacy Officer</div>
      <div>
        <a href="mailto:dpo@points.com?subject=Request from Points.com -.">
          dpo@points.com
        </a>
      </div>
      <div>Points.com Inc.</div>
      <div>111 Richmond St. W., Suite 700</div>
      <div>Toronto, ON, M5H 2G4</div>
      <div>Canada</div>
      <div>1-416-595-0000</div>
      <br />
      <p style={{textAlign: 'left'}}>
        In addition to contacting our Data Privacy Officer, individuals residing
        in the European Economic Area may contact our EU Representative at:{" "}
      </p>
      <div>EU Representative</div>
      <div>
        <a href="mailto:EURepresentative@points.com">
          EURepresentative@points.com
        </a>
      </div>
      <div>Points International (UK) Limited</div>
      <div>c/o</div>
      <div>Points.com Inc.</div>
      <div>111 Richmond St. W., Suite 700</div>
      <div>Toronto, ON, M5H 2G4</div>
      <div>Canada</div>
      <br />
      <div>1-416-595-0000</div>
      <p style={{textAlign: 'left'}}>
        Points will respond to your request for access in accordance with
        applicable privacy legislation.
      </p>
      <p style={{textAlign: 'left'}}>This Privacy Policy was last updated on December 6, 2023.</p>
      </div>
    </Content>
  </Layout>
);

export default PrivacyPage;
